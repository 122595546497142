<template>
    <kurcc-data-table-page-template :data-table-title="$vuetify.lang.t('$vuetify.pages.categories.categoryList')"
                                    :default-item="defaultItem"
                                    :delete-dialog-title="$vuetify.lang.t('$vuetify.pages.categories.deleteDialogText')"
                                    :headers="headers"
                                    :toolbar-button-dialog-title="$vuetify.lang.t('$vuetify.pages.categories.newCategory')"
                                    :toolbar-button-text="$vuetify.lang.t('$vuetify.pages.categories.newCategory')"
                                    :update-dialog-title="$vuetify.lang.t('$vuetify.pages.categories.editCategory')"
                                    actions-suffix="Category"
                                    show-select>
        <template v-slot:dialog="{item}">
            <kurcc-categories-dialog :item="item"/>
        </template>
    </kurcc-data-table-page-template>
</template>

<script>
import category from "@/modules/categories/models/category";

export default {
    name: "KurccCategoriesPage",
    components: {
        KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate'),
        KurccCategoriesDialog: () => import('@/modules/categories/components/KurccCategoriesDialog'),
    },
    data() {
        return {
            headers: [
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.categories.dataTableHeaders.category'),
                    align: 'start',
                    value: 'name',
                },
                {text: this.$vuetify.lang.t('$vuetify.pages.categories.dataTableHeaders.assignedForms'), value: ''},
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.categories.dataTableHeaders.assignedGroups'),
                    value: 'groupNames',
                    sortable: false
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.categories.dataTableHeaders.routineDuration'),
                    value: 'routine_inspection_duration_days'
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.categories.dataTableHeaders.deadline'),
                    value: 'deadline_days'
                },
                {text: this.$vuetify.lang.t('$vuetify.general.status'), value: 'enabled'},
                {text: this.$vuetify.lang.t('$vuetify.general.actions'), value: 'actions', sortable: false},
            ],
            defaultItem: new category(),
        }
    },
}
</script>
